var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-main",
        {
          staticClass: "pt-0 overflow-hidden",
          attrs: { fluid: "", tag: "section" }
        },
        [
          _c("TopBar", {
            ref: "topBar",
            attrs: { title: "Form Submissions" },
            on: { "show-notifications": _vm.onShowNotifications }
          }),
          _c(
            "v-navigation-drawer",
            {
              style: {
                "z-index": _vm.$vuetify.breakpoint.xsOnly ? 10 : 1,
                "background-color": "#fafafa"
              },
              attrs: {
                app: !_vm.$vuetify.breakpoint.xsOnly,
                right: "",
                permanent: _vm.showNotificationPane,
                "hide-overlay": "",
                width: "375px",
                stateless: "",
                absolute: _vm.$vuetify.breakpoint.xsOnly
              },
              model: {
                value: _vm.showNotificationPane,
                callback: function($$v) {
                  _vm.showNotificationPane = $$v
                },
                expression: "showNotificationPane"
              }
            },
            [
              _vm.showNotificationPane
                ? _c("NotificationsPane", {
                    on: {
                      "close-notification-pane": function($event) {
                        _vm.showNotificationPane = false
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.showAuthExpiredDialog
            ? _c("AuthExpiredDialog", {
                attrs: { showAuthExpiredDialog: _vm.showAuthExpiredDialog },
                on: {
                  "portal-login-success": function($event) {
                    _vm.showAuthExpiredDialog = false
                  },
                  "auth-expired-dialog-close": function($event) {
                    _vm.showAuthExpiredDialog = false
                  }
                }
              })
            : _vm._e(),
          _c("MapViewFormSubmissionView", { staticClass: "py-5" })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }