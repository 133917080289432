<template>
  <v-app>
    <v-main fluid tag="section" class="pt-0 overflow-hidden">
      <TopBar
        title="Form Submissions"
        @show-notifications="onShowNotifications"
        ref="topBar"
      />

      <v-navigation-drawer
        :app="!$vuetify.breakpoint.xsOnly"
        v-model="showNotificationPane"
        right
        :permanent="showNotificationPane"
        hide-overlay
        width="375px"
        stateless
        :absolute="$vuetify.breakpoint.xsOnly"
        :style="{
          'z-index': $vuetify.breakpoint.xsOnly ? 10 : 1,
          'background-color': '#fafafa',
        }"
      >
        <NotificationsPane
          v-if="showNotificationPane"
          @close-notification-pane="showNotificationPane = false"
        />
      </v-navigation-drawer>

      <AuthExpiredDialog
        v-if="showAuthExpiredDialog"
        :showAuthExpiredDialog="showAuthExpiredDialog"
        @portal-login-success="showAuthExpiredDialog = false"
        @auth-expired-dialog-close="showAuthExpiredDialog = false"
      />

      <MapViewFormSubmissionView class="py-5" />
    </v-main>
  </v-app>
</template>

<script>
import TopBar from "@/components/app/TopBar.vue";
import NotificationsPane from "@/components/shared/NotificationsPane.vue";
import MapViewFormSubmissionView from "@/components/mapView/MapViewFormSubmissionView";
import usetifulMixin from "@/mixins/usetifulMixin";
import AuthExpiredDialog from "@/components/app/AuthExpiredDialog";
import validateEsriTokenMixin from "@/mixins/validateEsriTokenMixin";

export default {
  name: "Tasks",
  components: {
    TopBar,
    NotificationsPane,
    MapViewFormSubmissionView,
    AuthExpiredDialog,
  },
  mixins: [usetifulMixin, validateEsriTokenMixin],
  data() {
    return {
      showNotificationPane: false,
      topBarHeight: 0,
    };
  },
  methods: {
    onShowNotifications() {
      this.showNotificationPane = true;
    },
    onResize() {
      this.topBarHeight = this.$refs.topBar?.$el?.clientHeight ?? 0;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "auto";
  },
  beforeMount() {
    this.checkEsriTokenExpirationPeriodically();
  },
  async mounted() {
    const [elHtml] = document.getElementsByTagName("html");
    elHtml.style.overflowY = "hidden";
    window.addEventListener("resize", this.onResize);
    await this.$nextTick();
    this.onResize();
  },
};
</script>
